// Set primary color
//$primary: #003366; // This is a shade of dark blue, not orange

//import mixing and variables
@use 'src/app/scss/_variables.scss';
@use 'src/app/scss/_mixins.scss';

// Import Bootstrap - this should come after importing Bootswatch variables
@import "node_modules/bootstrap/scss/bootstrap";
@import "@fontsource/libre-baskerville"; // Defaults to weight 400
@import '~@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arya:wght@400;700&display=swap');


// override inner props
//.btn-primary {
//    --bs-btn-hover-bg: #004080; // Your custom color for primary button hover
//}

.vh-85{
  height: 85vh;
}
.vh-75 {
    height: 75vh;
}
.vh-50 {
    height: 50vh;
  }
.h-85{
  height: 85%;
}
// Custom overrides
.btn {
  border-radius: 0.25rem;
}
  .btn-circle.btn-xl {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 24px;
    line-height: 1.33;
  }
  
  .btn-circle.btn-l {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 1.33;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }
  .btn-wide {
    padding-left: 3rem; /* Increase left padding */
    padding-right: 3rem; /* Increase right padding */
  }
  button .fas, button .far, button .fal, button .fab {
    font-size: xx-large; /* You can use specific sizes like '24px' or '1.5em' etc. */
  }
  .btn-toggle {
    width: 80px; /* Increase left padding */
  }

  div {
    font-size: large; /* Or use a specific size */
    // background-image: url("../../assets/abstract.jpg");
  }

  html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
}

//* CSS VARIABLE THEME STYLING

.ui-icon{
  width: 28px;
  height: auto;
}

body[theme="chic"] {

  // font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;

  // background-image: url("../../assets/abstract.jpg");
  // background-position: center;
  // background-repeat: repeat;
  // background-size: 20rem;
  background-image: url("../../assets/confetti.jpg");
  background-repeat: repeat;
  background-size: 18rem;
}

body[theme="joy"] {

  // font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;

  // background-image: url("../../assets/bubbles.jpg");
  background-image: url("../../assets/confetti.jpg");
  background-repeat: repeat;
  background-size: 18rem;
    // background-image:  repeating-linear-gradient(
    // 180deg, 
    // #fadde5, 
    // #fadde5 1.2px,
    // #FEF9F5 1.2px, 
    // #FEF9F5 5px
    // );

}

body[theme="fun"] {

  // font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;

  background-image: url("../../assets/confetti.jpg");
  background-repeat: repeat;
  background-size: 18rem;

}

.card-fix-width{
  width: var(--card-width);
}

.card-fix-height{
  height: 373px;
}
.border-dashed{
  border: #616161 3px dashed;
}
.border-solid{
  border: #616161 3px solid;
}

.abel-regular {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.open-sans {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.cdk-virtual-scroll-viewport{
  height: 100vh;
  width: 340px;
}