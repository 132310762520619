:root {
    --card-radius: 16px;
    --card-background-color: #ffffff;
    --card-stroke-color: #1d1d1d;
    --card-font-family: "Abel", system-ui;
    --card-width: 340px;
}

[theme=joy]{
    --se-hostname-font: "abel";
    --se-hostname-font-size: 2.6rem;
    --se-primary: #202020;
}
[theme=chic]{
    // --se-hostname-font: "Cormorant Garamond";
    // --se-hostname-font-size: 2.2rem;
    --se-hostname-font: "abel";
    --se-hostname-font-size: 2.6rem;
    --se-primary: #0e0e0e;
}
[theme=fun]{
    --se-hostname-font: "Arya";
    --se-hostname-font-size: 2.5rem;
    --se-hostname-line-height: 2.6rem;
    --se-primary: #202020;
}



// Override Bootstrap variable
$form-range-track-height: 0.1rem;
$form-range-track-bg: black;
$form-range-thumb-bg: black;
$light: #e9e9e9;
$dark: #8c8c8c;
$red: #c34d4d;
$green: #11827c;
$info: #8dc6ee;
$secondary: white;
